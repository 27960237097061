import { FC } from 'react'
import { GlobalDesktopHeader } from '@components/organisms/GlobalDesktopHeader'
import { GlobalMobileHeader } from '@components/organisms/GlobalMobileHeader'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  isFullWidth?: boolean
  isTranslucent?: boolean
  isTransparent?: boolean
}

export const GlobalHeader: FC<Props> = ({ isFullWidth = false, isTranslucent = false, isTransparent = false }) => {
  return (
    <header
      className={classNames(styles.container, {
        [styles.translucentContainer]: isTranslucent,
        [styles.transparentContainer]: isTransparent,
      })}
    >
      {isFullWidth ? (
        <div className={styles.fullWidthInner}>
          <div className={styles.desktop}>
            <GlobalDesktopHeader />
          </div>
          <div className={styles.mobile}>
            <GlobalMobileHeader />
          </div>
        </div>
      ) : (
        <div className='container'>
          <div className={styles.desktop}>
            <GlobalDesktopHeader />
          </div>
          <div className={styles.mobile}>
            <GlobalMobileHeader />
          </div>
        </div>
      )}
    </header>
  )
}
