import { FC } from 'react'
import {
  GlobalDesktopHeaderNavMenuDropdown,
  GlobalDeskTopNavSubMenu,
} from '@components/molecules/GlobalDesktopHeaderNavMenuDropdown'
import { observer } from 'mobx-react'
import Link from 'next/dist/client/link'
import { isJaAndAtTopPage, sanitizeUrl } from '@/utils'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import styles from './index.module.scss'

export type GlobalDeskTopNavMenuItem = {
  name: string
  isMultipleColumns: boolean
  href?: string
  subMenus: GlobalDeskTopNavSubMenu[]
}

type Props = GlobalDeskTopNavMenuItem

export const GlobalDesktopHeaderNavMenu: FC<Props> = observer(({ name, subMenus, href = '', isMultipleColumns }) => {
  const router = useRouter()
  if (subMenus.length === 0) {
    return (
      <Link href={sanitizeUrl(href)}>
        <a
          className={classNames(styles.containerWithoutDropDown, {
            [styles.textWhite]: isJaAndAtTopPage(router),
          })}
        >
          {name}
        </a>
      </Link>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.name}>{name}</div>
      <div className={styles.dropDown}>
        <GlobalDesktopHeaderNavMenuDropdown subMenus={subMenus} isMultipleColumns={isMultipleColumns} />
      </div>
    </div>
  )
})
