import Link from 'next/link'
import { FC } from 'react'
import { Logo } from '@components/atoms/Logo'
import { GlobalDesktopHeaderNavigation } from '@components/organisms/GlobalDesktopHeaderNavigation'
import { GlobalDesktopHeaderRight } from '@components/organisms/GlobalDesktopHeaderRight'
import styles from './index.module.scss'

export const GlobalDesktopHeader: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.container__box}>
        <div className={styles.left}>
          <Link href='/'>
            <a className={styles.logo}>
              <Logo />
            </a>
          </Link>
          <div className={styles.navigationMenu}>
            <GlobalDesktopHeaderNavigation />
          </div>
        </div>
        <div className={styles.right}>
          <GlobalDesktopHeaderRight />
        </div>
      </div>
    </div>
  )
}
