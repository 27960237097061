import { FC, useState } from 'react'
import classNames from 'classnames'
import { OutSideOverlay } from '@components/atoms/OutSideOverlay'
import MenuBlackSvg from '@/public/images/common/hamburger-menu-black.svg'
import MenuWhiteSvg from '@/public/images/common/hamburger-menu-white.svg'
import { GlobalMobileHeaderServiceMenuDropdown } from '@components/organisms/GlobalMobileHeaderServiceMenuDropdown'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { isJaAndAtTopPage } from '@/utils'
import styles from './index.module.scss'

export const GlobalMobileHeaderServiceMenu: FC = observer(() => {
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()

  const toggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.container}>
      <button className={styles.button} type='button' onClick={toggleDropDown}>
        <div className={styles.button__icon}>
          {isJaAndAtTopPage(router) ? <MenuWhiteSvg viewBox='0 0 24 24' /> : <MenuBlackSvg viewBox='0 0 24 24' />}
        </div>
      </button>
      <div
        className={classNames(styles.menu, {
          [styles.activeMenu]: isOpen,
        })}
      >
        <GlobalMobileHeaderServiceMenuDropdown toggleDropDown={toggleDropDown} />
      </div>
      <OutSideOverlay onClick={toggleDropDown} isOpen={isOpen} />
    </div>
  )
})
