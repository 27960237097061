import { FC, ReactNode } from 'react'
import { NavMenuItem } from '@components/atoms/NavMenuItem'
import { observer } from 'mobx-react'

export type GlobalDeskTopNavMenuItemChild = {
  label: string
  description: string
  href: string
  iconSvgNode: ReactNode
  isExternal: boolean
}

type Props = {
  items: GlobalDeskTopNavMenuItemChild[]
}

export const NavMenuItems: FC<Props> = observer(({ items }) => {
  return (
    <>
      {items.map(({ label, description, href, iconSvgNode, isExternal }) => (
        <NavMenuItem
          key={href}
          label={label}
          description={description}
          href={href}
          iconSvgNode={iconSvgNode}
          isExternal={isExternal}
        />
      ))}
    </>
  )
})
